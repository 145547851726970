import { handleActions } from 'redux-actions';
import * as actionCreators from '../actionCreators/addresses';

const defaultState = {
  isLoading: false,
  addressesData: [],
  activeAddress: { id: null, name: null, address: null, contact: null, location: null }, // For checkout
  dialog: {
    isOpen: false,
    selectedAddress: null, // For editing
  },
};

const addressesReducer = handleActions(
  {
    // Read from Address
    [actionCreators.doReadAddresses]: (state, { payload }) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [actionCreators.doReadAddressesSuccess]: (state, { payload }) => {
      return {
        ...state,
        addressesData: payload,
        isLoading: false,
      };
    },
    [actionCreators.doReadAddressesFailure]: (state, { payload }) => {
      return {
        ...state,
        isLoading: false,
      };
    },
    // Add To Address
    [actionCreators.doAddAddressSuccess]: (state, { payload }) => {
      return {
        ...state,
        addressesData: [...state.addressesData, payload],
      };
    },
    // Update Address
    [actionCreators.doUpdateAddressSuccess]: (state, { payload }) => {
      return {
        ...state,
        addressesData: state.addressesData.map((address) =>
          address.uuId === payload.uuId ? { ...address, ...payload } : address,
        ),
      };
    },
    // Remove Address
    [actionCreators.doRemoveAddressSuccess]: (state, { payload }) => {
      return {
        ...state,
        addressesData: state.addressesData.filter((item) => item.id !== payload.id),
      };
    },
    [actionCreators.doSetActiveAddress]: (state, { payload }) => ({
      ...state,
      activeAddress: payload,
    }),
    [actionCreators.doClearActiveAddress]: (state) => ({
      ...state,
      activeAddress: { ...defaultState.activeAddress },
    }),
    // Dialog
    [actionCreators.doOpenAddressDialog]: (state, { payload }) => ({
      ...state,
      dialog: {
        isOpen: true,
        selectedAddress: payload,
      },
    }),
    [actionCreators.doCloseAddressDialog]: (state) => ({
      ...state,
      dialog: {
        isOpen: false,
        selectedAddress: null,
      },
    }),
  },
  defaultState,
);

export default addressesReducer;

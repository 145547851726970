import React from 'react';
import '../../../node_modules/bootstrap/dist/css/bootstrap.css';
import { useDispatch, useSelector } from 'react-redux';
import { doOpenAddressDialog, doSetActiveAddress } from '../../redux/actionCreators/addresses';
import { selectAllAddresses, selectActiveAddress } from '../../redux/selectors/addresses';
import { deliveryTypes } from '../../config/constants';
import AddressDialog from './AddressDialog';
import { selectAllLocations } from '../../redux/selectors/locations';
import { doUpdateProfile } from '../../redux/actionCreators/authentication';

//For the address and location dialogs
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import { TextField } from '@material-ui/core';
import Select from 'react-select';

import _ from 'lodash';

const borderStyle = {
  border: '3px solid #62321E',
};

const Address = (props) => {
  const dispatch = useDispatch();
  const addresses = useSelector(selectAllAddresses);
  const activeAddress = useSelector(selectActiveAddress);
  const allLocations = useSelector(selectAllLocations);
  const { deliveryType, checkout } = props;
  const [openLocationDialog, setOpenLocationDialog] = React.useState(false);
  const [openBillingAddressDialog, setOpenBillingAddress] = React.useState(false);

  const initialBillingAddress = {
    firstName: props.userData.firstName,
    phone: Number(props.userData.phone.slice(3, 13)),
    address: props.userData.address,
    locationId: props.userData.locationId,
  };

  function closeBillingAddressDialog() {
    setOpenBillingAddress(false);
    dispatch(
      doUpdateProfile({
        address: initialBillingAddress.address,
        locationId: initialBillingAddress.locationId,
      }),
    );
  }

  function handleOpenBillingAddress() {
    setOpenBillingAddress(true);
  }

  function selectBillingAddress(item) {
    if (item.locationId !== null) {
      const billingLocation = allLocations.filter((loc) => loc.id === item.locationId);
      const billingAddress = {
        id: item.id,
        name: item.firstName,
        address: item.address,
        contact: item.phone,
        location: billingLocation[0],
      };
      dispatch(doSetActiveAddress(billingAddress));
    } else {
      setOpenLocationDialog(true);
    }
  }

  function updateProfileLocation(itemId) {
    initialBillingAddress['locationId'] = itemId;
  }

  function closeLocationsDialog() {
    setOpenLocationDialog(false);
  }

  function handleAddressChange(e) {
    initialBillingAddress['address'] = e.target.value;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6">
          <h4 className="address-heading">Billing Address</h4>
          <hr />
          <div style={{ display: props.userData.address === null ? 'none' : null }}>
            <div
              className="row address-styling align-items-center"
              style={
                props.userData.id === activeAddress.id && deliveryType === deliveryTypes.delivery
                  ? borderStyle
                  : null
              }
            >
              <div className="col-1 col-md-1 radio-button">
                <input
                  type="radio"
                  onChange={() => selectBillingAddress(props.userData)}
                  checked={props.userData.id === activeAddress.id}
                  disabled={checkout && deliveryType !== deliveryTypes.delivery}
                />
              </div>
              <div className="col-11 col-md-11 info">
                {/* style={{ marginLeft: '15px', marginTop: '10px' }} */}
                <span className="user-name">{props.userData.firstName}</span>
                {/* style={{ marginLeft: '15px' }} */}
                <p className="user-address">{`${props.userData.address}`}</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-12 d-flex flex-row p-0">
            <div>
              <h5 className="address-heading m-0 " style={{ fontSize: '15px' }}>
                Location:
              </h5>
              <hr style={{ float: 'left', width: '70%' }} />
            </div>
            <div>
              <p className="m-0 text-secondary" style={{ padding: '0 5px' }}>
                {props.userData.location.name}
              </p>
            </div>
          </div>

          <button
            className="btn btn-secondary add-address-button"
            onClick={handleOpenBillingAddress}
            style={{
              display:
                (checkout && deliveryType !== deliveryTypes.delivery) ||
                props.userData.address !== null
                  ? 'none'
                  : null,
            }}
          >
            Add Address
          </button>

          {/* Dialog to add a location if the original value is null */}
          <Dialog
            open={openLocationDialog}
            onClose={closeLocationsDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{'Add Location to continue'}</DialogTitle>
            <DialogContent>
              <Box py={2}>
                <InputLabel htmlFor="address-location">Location</InputLabel>
                <Select
                  id="address-location"
                  options={_.sortBy(allLocations, [(location) => location.name])}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  fullWidth
                  placeholder="Select Location"
                  onChange={(location) => updateProfileLocation(location.id)}
                  value={initialBillingAddress.locationId}
                  required
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <button onClick={closeLocationsDialog} className="btn btn-primary">
                Add
              </button>
            </DialogActions>
          </Dialog>

          {/* Dialog to add a new billing address */}
          <Dialog
            open={openBillingAddressDialog}
            onClose={closeBillingAddressDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{'Add Billing Address'}</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                name="name"
                label="First name"
                type="text"
                variant="standard"
                defaultValue={initialBillingAddress.firstName}
                required
                fullWidth
                disabled
              />
              <br />
              <TextField
                autoFocus
                margin="dense"
                name="phone"
                label="Contact Number"
                type="number"
                variant="standard"
                defaultValue={initialBillingAddress.phone}
                disabled
                required
                fullWidth
              />
              <br />
              <TextField
                autoFocus
                margin="dense"
                name="address"
                label="Address"
                type="text"
                variant="standard"
                onChange={handleAddressChange}
                required
                fullWidth
              />
              <Box py={2}>
                <InputLabel htmlFor="address-location">Location</InputLabel>
                <Select
                  id="address-location"
                  options={_.sortBy(allLocations, [(location) => location.name])}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  fullWidth
                  placeholder="Select Location"
                  onChange={(location) => updateProfileLocation(location.id)}
                  required
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <button onClick={closeBillingAddressDialog} className="btn btn-primary">
                Add
              </button>
            </DialogActions>
          </Dialog>
        </div>
        <div className="col-12 col-lg-6 ship-add">
          <h4 className="address-heading">Shipping Address</h4>
          <hr />
          <div className="container address-container">
            {addresses.map((item) => (
              <>
                <div
                  className="row address-styling align-items-center"
                  style={
                    item.id === activeAddress.id && deliveryType === deliveryTypes.delivery
                      ? borderStyle
                      : null
                  }
                  key={item.id}
                >
                  <div className="col-1 col-md-1 radio-button m-0">
                    <input
                      type="radio"
                      onChange={() => dispatch(doSetActiveAddress(item))}
                      checked={item.id === activeAddress.id}
                      disabled={checkout && deliveryType !== deliveryTypes.delivery}
                    />
                  </div>
                  <div className="col-6 col-md-7">
                    <div className="info">
                      <span className="user-name">{item.name}</span>
                      <p className="user-address">{`${item.address}`}</p>
                    </div>
                  </div>
                  <div className="col-5 col-md-4 edit-button">
                    <button
                      className="btn btn-outline-primary "
                      onClick={() => dispatch(doOpenAddressDialog(item))}
                      disabled={checkout && deliveryType !== deliveryTypes.delivery}
                    >
                      Click to edit
                    </button>
                  </div>
                </div>
                <div className="col-12 col-lg-12 d-flex flex-row p-0">
                  <div>
                    <h5 className="address-heading m-0 " style={{ fontSize: '15px' }}>
                      Location:
                    </h5>
                    <hr style={{ float: 'left', width: '70%' }} />
                  </div>
                  <div>
                    <p className="m-0 text-secondary" style={{ padding: '0 5px' }}>
                      {item.location.name}
                    </p>
                  </div>
                </div>
              </>
            ))}
          </div>
          <button
            className="btn btn-secondary add-address-button"
            onClick={() => dispatch(doOpenAddressDialog(null))}
            disabled={checkout && deliveryType !== deliveryTypes.delivery}
          >
            Add Address
          </button>
          <AddressDialog />
        </div>
      </div>
    </div>
  );
};

export default Address;

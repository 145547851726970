import React from 'react';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import {
  doAddAddress,
  doUpdateAddress,
  doCloseAddressDialog,
  doRemoveAddress,
  doSetActiveAddress,
} from '../../redux/actionCreators/addresses';
import { selectAddressDialog, selectActiveAddress } from '../../redux/selectors/addresses';
import { selectAllLocations } from '../../redux/selectors/locations';
import { selectUserData } from '../../redux/selectors/authentication';

import _ from 'lodash';
// Formik
import { Formik, Form, Field } from 'formik';

// Mui
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import * as Yup from 'yup';

import Select from 'react-select';
const AddressDialog = (props) => {
  const dispatch = useDispatch();
  const locations = useSelector(selectAllLocations);
  const userData = useSelector(selectUserData);
  const activeAddress = useSelector(selectActiveAddress);
  const { isOpen, selectedAddress } = useSelector(selectAddressDialog);
  const newAddress = selectedAddress === null;

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    if (newAddress) {
      const payload = { ...values, customerId: userData.id };
      delete payload.location;
      dispatch(doAddAddress(payload));
    } else {
      if (activeAddress.id === values.id) {
        const billingAddress = {
          id: values.id,
          name: values.name,
          address: values.address,
          contact: values.phone,
          location: values.location,
          gstNumber: values.gstNumber,
          customerCode: values.customerCode,
        };
        dispatch(doSetActiveAddress(billingAddress));
      }
      dispatch(doUpdateAddress(values));
    }
  };

  const handleClose = () => {
    dispatch(doCloseAddressDialog());
  };

  function validatePhoneNumber(value) {
    let error;
    if (value == null) {
      error = 'Contact number field cannot be empty';
    } else {
      if (value.length > 10) {
        error = 'Phone number must be 10 digits';
      }
    }
    return error;
  }

  const SignupSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    address: Yup.string().required('Required'),
    phone: Yup.number().required('Required'),
  });

  return (
    <Dialog open={isOpen} onClose={handleClose} aria-labelledby="address-dialog">
      <DialogTitle id="address-dialog">{newAddress ? 'New' : 'Edit'} Address</DialogTitle>

      <DialogContent>
        <Formik
          initialValues={{ ...selectedAddress }}
          enableReinitialize
          onSubmit={handleSubmit}
          validationSchema={SignupSchema}
        >
          {({ submitForm, setFieldValue, values, errors, touched }) => (
            <Form>
              <Field
                component={TextField}
                autoFocus
                margin="dense"
                name="name"
                type="text"
                label="Name"
                fullWidth
                onChange={(e) => setFieldValue('name', e.target.value)}
                value={values.name}
                required
              />
              {errors.name && touched.name ? (
                <div style={{ color: 'red' }}>{errors.name}</div>
              ) : null}
              <Field
                component={TextField}
                margin="dense"
                name="address"
                type="text"
                label="Address"
                fullWidth
                onChange={(e) => setFieldValue('address', e.target.value)}
                value={values.address}
                required
              />
              {errors.address && touched.address ? (
                <div style={{ color: 'red' }}>{errors.address}</div>
              ) : null}
              <Field
                component={TextField}
                margin="dense"
                name="phone"
                type="number"
                label="Contact Number"
                fullWidth
                onChange={(e) => setFieldValue('phone', e.target.value)}
                value={values.phone}
                validate={validatePhoneNumber}
                required
              />
              {errors.phone && touched.phone ? (
                <div style={{ color: 'red' }}>{errors.phone}</div>
              ) : null}
              <Field
                component={TextField}
                autoFocus
                margin="dense"
                name="gst"
                type="text"
                label="GST Number"
                fullWidth
                onChange={(e) => setFieldValue('gstNumber', e.target.value)}
                value={values.gstNumber}
                required
              />
              {errors.gstNumber && touched.gstNumber ? (
                <div style={{ color: 'red' }}>{errors.gstNumber}</div>
              ) : null}
              <Field
                component={TextField}
                autoFocus
                margin="dense"
                name="customerCode"
                type="text"
                label="Customer Code"
                fullWidth
                onChange={(e) => setFieldValue('customerCode', e.target.value)}
                value={values.customerCode}
                required
              />
              {errors.customerCode && touched.customerCode ? (
                <div style={{ color: 'red' }}>{errors.customerCode}</div>
              ) : null}
              <Box py={2}>
                <InputLabel htmlFor="address-location">Location</InputLabel>
                <Select
                  id="address-location"
                  options={_.sortBy(locations, [(location) => location.name])}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  fullWidth
                  placeholder="Select Location"
                  onChange={(location) => {
                    setFieldValue('locationId', location.id);
                    setFieldValue('location', location);
                  }}
                  defaultValue={selectedAddress !== null ? [values.location] : undefined}
                  required
                />
              </Box>

              <Box display="flex" justifyContent="space-between">
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>

                <Box>
                  {!newAddress ? (
                    <Button onClick={() => dispatch(doRemoveAddress(selectedAddress))}>
                      Remove
                    </Button>
                  ) : null}

                  <Button onClick={submitForm} color="primary">
                    {newAddress ? 'Add' : 'Update'}
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddressDialog;

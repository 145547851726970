import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import '../../../node_modules/bootstrap/dist/css/bootstrap.css';
import './styles.css';
import Button from '@material-ui/core/Button';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import moment from 'moment';

import { RZP_API_KEY, deliveryTypes, paymentTypes, creditLimitPerc } from '../../config/constants';
import { confirmCart, capturePayment, captureCod, captureCredit } from '../../services/APIService';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { selectActiveAddress } from '../../redux/selectors/addresses';
import { selectPickupLocation } from '../../redux/selectors/locations';
import { doReadCartItems } from '../../redux/selectors/cart';
import calculateDeliveryDate from '../../utils/calculateDeliveryDate';
import isFollowingDayDelivery from '../../utils/isFollowingDayDelivery';
import {
  doClearCart,
  doUpdateCart,
  doSetDeliveryType,
  doSetPaymentType,
  doReadCart,
} from '../../redux/actionCreators/cart';
import { doReadPickupLocation } from '../../redux/actionCreators/locations';
import { doManageNotification } from '../../redux/actionCreators/miscellaneous';

//constants
import { codAddress } from '../../config/constants/index';
import { isMinOrderQtyFulfilled } from '../../utils/order';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';

const Checkout = (props) => {
  const { userData, deliveryType, paymentType, setValue } = props;
  const [specialRequest, setSpecialRequest] = useState('None');
  const [po, setpo] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(doReadCart());
  }, [dispatch]);

  const activeAddress = useSelector(selectActiveAddress);
  const pickupAddress = useSelector(selectPickupLocation);
  const history = useHistory();

  const [isUpdateDeliveryDateOpen, setIsUpdateDeliveryDateOpen] = useState(false);
  const cartData = useSelector(doReadCartItems);
  const [checkoutDis, setCheckoutDisable] = useState(false);
  const [checkoutBtnDisable, setCheckoutBtnDisable] = useState(false);

  // possible values from getDay function = (0 | 1 | 2 | 3 | 4 | 5 | 6) , Look at it as an index in an array where Sunday is the first element, and Saturday is the last.
  let gst = 0;
  let subtotal = 0;
  const minimumOrderValue = 500;

  props.items.forEach((i) => {
    gst += (i.price * i.gstPerc * i.quantity) / 100;
    subtotal += i.price * i.quantity;
  });

  gst = Number(gst);
  subtotal = Number(subtotal);

  const handleDeliveryTypeChange = (e) => {
    dispatch(doSetDeliveryType(e.target.value));
    if (e.target.value === deliveryTypes.delivery) {
      dispatch(doSetPaymentType(paymentTypes.card));
    }
  };

  const handlePaymentTypeChange = (e) => dispatch(doSetPaymentType(e.target.value));

  useEffect(() => {
    if (userData.role === 'customer' && subtotal + gst < minimumOrderValue) {
      setCheckoutDisable(true);
    } else {
      setCheckoutDisable(false);
    }
    if (subtotal === 0) {
      dispatch(doSetDeliveryType(deliveryTypes.delivery));
      dispatch(doSetPaymentType(paymentTypes.cod));
    } else {
      dispatch(doSetDeliveryType(deliveryTypes.delivery));
      if (userData && userData.role === 'corporate') {
        dispatch(doSetPaymentType(paymentTypes.credit));
      } else {
        dispatch(doSetPaymentType(paymentTypes.card));
      }
      // dispatch(doSetPaymentType(paymentTypes.card));
    }

    dispatch(doReadPickupLocation());
  }, [dispatch, subtotal, gst, userData]);

  const isCreditInsufficient = () =>
    Number(userData.currentCredit) + subtotal + gst >
    creditLimitPerc * Number(userData.creditLimit);

  let add;
  let gstNumber = '';
  let customerCode = '';
  let customerAddressName = '';

  const handleCheckout = async () => {
    if (checkoutBtnDisable) {
      return;
    }
    setCheckoutBtnDisable(true);
    setTimeout(function () {
      setCheckoutBtnDisable(false);
    }, 4000);
    // Add delivery price here prolly
    const isFollowingDay = isFollowingDayDelivery();
    // console.log("🚀 ~ file: checkout.js ~ line 88 ~ handleCheckout ~ isFollowingDay", isFollowingDay)
    let hasUpdatedFlag = false;
    if (isFollowingDay) {
      cartData.forEach(function (cart) {
        const newDeliDate = calculateDeliveryDate(cart, cart.quantity);
        const cartDeliDate = moment(cart.deliveryDate);
        // console.log('🚀 ~ file: checkout.js ~ line 98 ~ newDeliDate', newDeliDate);
        // console.log('🚀 ~ file: checkout.js ~ line 99 ~ cartDeliDate', cartDeliDate);

        if (cartDeliDate.isBefore(newDeliDate)) {
          hasUpdatedFlag = true;
          dispatch(
            doUpdateCart({
              ...cart,
              quantity: cart.quantity,
              deliveryDate: newDeliDate,
            }),
          );
        }
      });
    }
    if (hasUpdatedFlag) {
      setIsUpdateDeliveryDateOpen(true);
      return false;
    }
    // console.log('hasUpdatedFlag', hasUpdatedFlag);
    // return false;

    try {
      if (paymentType === paymentTypes.credit && isCreditInsufficient()) {
        dispatch(
          doManageNotification({
            isVisible: true,
            message: 'Credit Insufficient',
          }),
        );
        return;
      }

      const confirmResponse = await confirmCart(
        Number(subtotal.toFixed(2)),
        Number(gst.toFixed(2)),
        paymentType,
      );

      let commonCaptureData;
      if (paymentType === paymentTypes.cod) {
        add = activeAddress === null ? '' : activeAddress?.address;
        gstNumber = activeAddress === null ? '' : activeAddress?.gstNumber || '';
        customerCode = activeAddress === null ? '' : activeAddress?.customerCode || '';
        customerAddressName = activeAddress === null ? '' : activeAddress?.name || '';
        let locationId = activeAddress?.location?.id;
        if (deliveryType === deliveryTypes.pickup) {
          add = codAddress === null ? '' : codAddress;
          locationId = pickupAddress.id;
        }
        commonCaptureData = {
          locationId,
          address: add,
          gstNumber,
          customerCode,
          customerAddressName,
          specialRequest,
          orderType: deliveryType,
          po,
        };
      } else if (
        deliveryType === deliveryTypes.pickup &&
        (paymentType === paymentTypes.credit || paymentType === paymentTypes.card)
      ) {
        // console.log('working')
        if (paymentType === paymentTypes.credit && isCreditInsufficient()) {
          dispatch(
            doManageNotification({
              isVisible: true,
              message: 'Credit Insufficient',
            }),
          );
          return;
        }
        add = codAddress === null ? '' : codAddress;
        commonCaptureData = {
          locationId: pickupAddress.id,
          address: codAddress,
          specialRequest,
          orderType: deliveryType,
          po,
          customerAddressName: '',
          customerCode: '',
          gstNumber: '',
        };
      } else {
        add = activeAddress === null ? '' : activeAddress?.address;
        gstNumber = activeAddress === null ? '' : activeAddress?.gstNumber || '';
        customerCode = activeAddress === null ? '' : activeAddress?.customerCode || '';
        customerAddressName = activeAddress === null ? '' : activeAddress?.name || '';
        commonCaptureData = {
          locationId: activeAddress?.location?.id,
          address: activeAddress?.address,
          gstNumber,
          customerCode,
          customerAddressName,
          specialRequest,
          orderType: deliveryType,
          po,
        };
      }

      switch (paymentType) {
        case paymentTypes.cod:
          await captureCod(commonCaptureData);
          dispatch(
            doManageNotification({
              isVisible: true,
              message: 'Checkout successful',
            }),
          );
          dispatch(doClearCart());
          // console.log('Printing order id: ', codData.data.data.orderId)
          // dispatch(doSendSummary(codData.data.data.orderId));
          history.push('/orders');
          break;

        case paymentTypes.credit:
          await captureCredit(commonCaptureData);
          dispatch(
            doManageNotification({
              isVisible: true,
              message: 'Checkout successful',
            }),
          );
          dispatch(doClearCart());
          // dispatch(doSendSummary(orderId));
          history.push('/orders');
          break;

        case paymentTypes.card:
          const orderId = confirmResponse.data.data.orderId;
          const options = {
            key: RZP_API_KEY,
            name: 'Myleshigh Bakehouse',
            order_id: orderId,
            description: "Goa's first online bakery",
            // Replace with bakehouse logo/branding
            image: 'https://cdn.razorpay.com/logos/7K3b6d18wHwKzL_medium.png',
            handler: async function (response) {
              const razorPayPaymentId = response.razorpay_payment_id;
              const razorPayOrderId = response.razorpay_order_id;
              const razorPaySignature = response.razorpay_signature;

              const paymentData = {
                razorPayPaymentId,
                razorPayOrderId,
                razorPaySignature,
                ...commonCaptureData,
                po,
              };
              await capturePayment(paymentData);
              dispatch(doManageNotification({ isVisible: true, message: 'Checkout successful' }));
              dispatch(doClearCart());
              // console.log(`Printing Order id: ${orderId}`)
              // dispatch(doSendSummary(orderId));
              history.push('/orders');
            },
            prefill: {
              name: `${userData.firstName} ${userData.lastName}`,
              contact: userData.phone,
              email: userData.email,
            },
            notes: {
              address: activeAddress.name,
            },
            theme: {
              color: '#F2BD82',
              hide_topbar: false,
            },
          };

          const rzp1 = new window.Razorpay(options);
          rzp1.open();
          break;

        default:
          dispatch(
            doManageNotification({
              isVisible: true,
              message: 'Invalid Payment Method',
            }),
          );
          break;
      }
    } catch (error) {
      /* eslint-disable no-console */
      console.log('???///');
      console.log(error);
      console.log('???////');
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        'Error while checking out';

      dispatch(doManageNotification({ isVisible: true, message: message }));
    }
  };

  const addressDetails = (
    <>
      <br />
      <TableContainer>
        <Table aria-label="a dense table">
          <TableBody>
            <TableRow style={{ cursor: 'pointer' }} onClick={() => setValue(1)}>
              <TableCell align="left">Deliver To</TableCell>
              <TableCell className="font-weight-bold" align="right">
                {activeAddress.name}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">Location</TableCell>
              <TableCell className="font-weight-bold" align="right">
                {activeAddress.location && activeAddress.location.name}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );

  return (
    <>
      {activeAddress.name === '' || deliveryType !== deliveryTypes.delivery ? null : addressDetails}
      <br />
      <TableContainer>
        <Table size="medium" aria-label="a dense table">
          <TableBody>
            <TableRow>
              <TableCell colSpan={2} style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                <RadioGroup
                  row
                  name="Delivery Type"
                  value={deliveryType}
                  onChange={handleDeliveryTypeChange}
                >
                  <FormControlLabel
                    style={{ marginRight: '8px' }}
                    value={deliveryTypes.delivery}
                    control={<Radio />}
                    label="Delivery"
                  />
                  {/* <span style={{ marginLeft: '20px' }} /> */}
                  {subtotal ? (
                    <FormControlLabel
                      style={{ marginRight: '8px' }}
                      value={deliveryTypes.pickup}
                      control={<Radio />}
                      label="Pickup"
                    />
                  ) : null}
                </RadioGroup>
              </TableCell>
            </TableRow>
            <br />
            <TableRow>
              <TableCell colSpan={2} style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                <RadioGroup
                  row
                  name="Payment Type"
                  value={paymentType}
                  onChange={handlePaymentTypeChange}
                >
                  {deliveryType === deliveryTypes.delivery && subtotal !== 0 ? null : (
                    <FormControlLabel
                      style={{ marginRight: '8px' }}
                      value={paymentTypes.cod}
                      control={<Radio />}
                      label="COD"
                    />
                  )}

                  {subtotal ? (
                    <FormControlLabel
                      style={{
                        marginRight: deliveryTypes.delivery === deliveryType ? null : '15px',
                        marginLeft: deliveryTypes.delivery === deliveryType ? null : '15px',
                      }}
                      value={paymentTypes.card}
                      control={<Radio />}
                      label="Pay Online"
                    />
                  ) : null}

                  {userData && userData.role === 'corporate' && subtotal ? (
                    <FormControlLabel
                      style={{ marginRight: '8px' }}
                      value={paymentTypes.credit}
                      control={<Radio />}
                      label="Credit"
                    />
                  ) : null}
                </RadioGroup>
              </TableCell>
            </TableRow>
            {/* <br />
            <TableRow>
              <TableCell />
              <TableCell align="left">Delivery Fee</TableCell>
              <TableCell>{`Rs. ${0}`}</TableCell>
            </TableRow> */}
            <TableRow>
              <TableCell align="left">Subtotal</TableCell>
              <TableCell className="font-weight-bold" align="right">{`₹${subtotal.toFixed(
                2,
              )}`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">GST</TableCell>
              <TableCell className="font-weight-bold" align="right">
                {' '}
                {`₹${gst.toFixed(2)}`}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">Total</TableCell>
              <TableCell className="font-weight-bold" align="right">{`₹${Math.round(
                subtotal + gst,
              )}`}</TableCell>
            </TableRow>
            <br />

            <TableRow>
              <TableCell colSpan={2}>
                <TextField
                  fullWidth
                  label="Special Request"
                  multiline
                  variant="outlined"
                  value={specialRequest}
                  onChange={(e) => setSpecialRequest(e.target.value)}
                />
              </TableCell>
            </TableRow>

            {checkoutDis && (
              <Typography style={{ color: 'red', padding: '16px' }}>
                Minimum Order value should be Rs. 500.
              </Typography>
            )}

            <TableRow>
              <TableCell colSpan={2}>
                <Button
                  className="btn btn-primary btn-check text-white"
                  fullWidth
                  disabled={
                    props.items.length === 0 ||
                    !isMinOrderQtyFulfilled(props.items) ||
                    !(activeAddress.name !== null || deliveryType !== deliveryTypes.delivery) ||
                    userData.blockFlag === 1 ||
                    checkoutDis ||
                    checkoutBtnDisable
                  }
                  onClick={() => handleCheckout()}
                >
                  PLACE ORDER
                </Button>
              </TableCell>
            </TableRow>

            {userData.role === 'customer' ? null : (
              <TableRow>
                <TableCell colSpan={2}>
                  <TextField
                    fullWidth
                    label="Purchase Order Number"
                    multiline
                    variant="outlined"
                    value={po}
                    onChange={(e) => setpo(e.target.value)}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={isUpdateDeliveryDateOpen}
        fullWidth
        maxWidth="sm"
        onClick={() => setIsUpdateDeliveryDateOpen(false)}
      >
        <DialogTitle>Delivery Date/s Updated</DialogTitle>
        <DialogContent>
          <Typography>
            Delivery date/s has been updated for one or more of your items because it is before our
            minimum date and time for your order items. Please verify delivery dates and continue
            with the checkout
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsUpdateDeliveryDateOpen(false)}
          >
            I Understand
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Checkout;

import { call, put, takeLatest } from 'redux-saga/effects';
import * as actionCreators from '../actionCreators/addresses';
import * as actionTypes from '../actionTypes/addresses';
import * as misc from '../actionCreators/miscellaneous';
import * as APIService from '../../services/APIService';

function* addAddress({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(APIService.addAddress, payload);
    yield put(misc.doManageNotification({ isVisible: true, message: 'Added Address' }));
    yield put(actionCreators.doAddAddressSuccess(data));
    yield put(actionCreators.doCloseAddressDialog());
  } catch (error) {
    const message = error.response.data.message || "Couldn't add address";
    yield put(misc.doManageNotification({ isVisible: true, message }));
    yield put(actionCreators.doAddAddressFailure());
  }
}

function* readAddresses({ payload }) {
  try {
    const {
      data: {
        data: { addresses },
      },
    } = yield call(APIService.readAddresses, payload);
    yield put(actionCreators.doReadAddressesSuccess(addresses));
  } catch (error) {
    yield put(
      misc.doManageNotification({ isVisible: true, message: "Couldn't fetch your addresses" }),
    );
    yield put(actionCreators.doReadAddressesFailure());
  }
}

function* updateAddress({ payload }) {
  try {
    const { customerId, id, name, phone, address, locationId, gstNumber, customerCode } = payload;
    yield call(APIService.updateAddress, customerId, id, {
      name,
      phone,
      address,
      locationId,
      gstNumber,
      customerCode,
    });
    yield put(misc.doManageNotification({ isVisible: true, message: 'Updated Address' }));
    yield put(actionCreators.doUpdateAddressSuccess(payload));
    yield put(actionCreators.doCloseAddressDialog());
  } catch (error) {
    const message = error.response.data.message || "Couldn't update your address";
    yield put(misc.doManageNotification({ isVisible: true, message }));
    yield put(actionCreators.doUpdateAddressFailure());
  }
}

function* removeAddress({ payload }) {
  try {
    const { customerId, id } = payload;
    yield call(APIService.removeAddress, customerId, id);
    yield put(misc.doManageNotification({ isVisible: true, message: 'Removed Address' }));
    yield put(actionCreators.doRemoveAddressSuccess(payload));
    yield put(actionCreators.doClearActiveAddress());
    yield put(actionCreators.doCloseAddressDialog());
  } catch (error) {
    const message = error.response.data.message || "Couldn't remove address";
    yield put(misc.doManageNotification({ isVisible: true, message }));
    yield put(actionCreators.doRemoveAddressFailure());
  }
}

const addressesSaga = [
  takeLatest(actionTypes.ADD_ADDRESS_REQUESTED, addAddress),
  takeLatest(actionTypes.READ_ADDRESSES_REQUESTED, readAddresses),
  takeLatest(actionTypes.UPDATE_ADDRESS_REQUESTED, updateAddress),
  takeLatest(actionTypes.REMOVE_ADDRESS_REQUESTED, removeAddress),
];

export default addressesSaga;
